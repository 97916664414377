// @flow
/**
 * @module Actions/User
 * @desc User Actions
 */
/* eslint-disable */
import { createActions } from 'redux-actions';

import { ActionTypes } from '../constants/index';

export const { getZippr } = createActions({
  [ActionTypes.GET_ZIPPR]: (zippr) => ({ zippr })
});