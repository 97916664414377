/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

const RoutePublic = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (<Component {...props} />)}
  />
);

RoutePublic.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
};

RoutePublic.defaultProps = {
  to: '/private',
};

export default RoutePublic;
