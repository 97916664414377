/* eslint-disable */
import React from 'react';
import styled from 'styled-components';

const BackDrop = styled.div`
    z-index: 7;
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,.50);
    ${({ show }) => !show && `
    display: none;
  `}
`;

const Backdrop = (props) => {
    return(
        <BackDrop onClick={props.hide} show={props.show}></BackDrop>
    )
}

export default Backdrop;