/* eslint-disable */
import React, { useState } from 'react';
import styled from 'styled-components';
import SearchIcon from '@material-ui/icons/Search';
import ShareIcon from '@material-ui/icons/Share';
import CommentIcon from '@material-ui/icons/Comment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Services from './Services';
import DirectionPanel from './DirectionPanel';

const Panel = styled.div`
    position:absolute;
    top:0;
    width:410px;
    height:100%;
    background-color:#fff;
    box-shadow:0 3px 6px 0 rgba(0,0,0,.16);
    z-index:6;
    ${({ open }) => !open && `
    width: 0;
  `}
`;

const StaticPanel = styled.div`
    height:348px;
    padding:10px;
    margin-bottom:10px
`;

const SearchPanel = styled.div`

`;

const ImagePanel = styled.div`
    position:relative;
    background-image:-webkit-linear-gradient(left,rgba(0,0,0,.05) 0,rgba(0,0,0,.05) 100%);
    background-image:-o-linear-gradient(left,rgba(0,0,0,.05) 0,rgba(0,0,0,.05) 100%);
    background-image:-webkit-gradient(linear,left top,right top,from(rgba(0,0,0,.05)),to(rgba(0,0,0,.05)));
    background-image:linear-gradient(to right,rgba(0,0,0,.05) 0,rgba(0,0,0,.05) 100%);
    background-repeat:repeat-x
`;

const ImgPanel = styled.div`
    width: 100%;
    text-align:center;
`;

const ZipprImage = styled.img`
    height:293px;
`;

const ScrollPanel = styled.div`
    padding:10px;
    height:-moz-calc(100% - 348px);
    height:-webkit-calc(100% - 348px);
    height:calc(100% - 348px);
    overflow-y:scroll
`;

const InfoPanel = styled.div`
    display:flex;
    box-shadow:0 2px 3px 0 rgba(0,0,0,.16)
`;

const LogoPanel = styled.div`
    padding:5px;
`;

const Logo = styled.img`
    width:50px;
    height: 50px;
    margin-top: 10px;
`;

const AddressPanel = styled.div`
    margin-left: 5px;
    width:-moz-calc(100% - 60px);
    width:-webkit-calc(100% - 60px);
    width:calc(100% - 60px);
    padding-top: 5px;
    padding-right: 5px;
`;

const TitleBar = styled.div`

`;

const AddressTitle = styled.span`
    font-family:Roboto;
    height:24px;
    font-size:18px;
    font-weight:400;
    font-style:normal;
    font-stretch:normal;
    line-height:1.13;
    letter-spacing:normal;
    text-align:left;
    color:rgba(0,0,0,.54);
`;

const AddressTextBar = styled.div`
    
    font-family:Roboto;
    font-size:15px;
    font-weight:400;
    font-style:normal;
    font-stretch:normal;
    line-height:1.33;
    letter-spacing:normal;
    text-align:left;
    color:rgba(0,0,0,.29);
    margin-top:5px;
    overflow: hidden;
    ${({ expanded }) => !expanded && `
    height: 56px;
  `}
`;

const ServiceHeader = styled.div`
    clear:both;
    margin-bottom:-3px;
`;

const SearchBox = styled.input`
    height:40px;
    border-radius:0;
    padding-left:42.5px;
    font-family:Roboto;
    font-size:14px;
    font-weight:400;
    font-style:normal;
    font-stretch:normal;
    line-height:1.44;
    letter-spacing:normal;
    text-align:left;
    color:rgba(0,0,0,.87);
    box-shadow:0 3px 6px 0 rgba(0,0,0,.16);
    background-color:#fafafa;
    border:0;
    display: block;
    width: 100%;
    :focus {
        outline: none;
    };
    ::-webkit-input-placeholder{
        font-weight:400;
        text-align:center
    };
    :-moz-placeholder{
        text-align:center
    };
    ::-moz-placeholder{
        text-align:center
    };
    :-ms-input-placeholder{
        text-align:center
    };
    ${({ open }) => !open && `
    display: none;
  `}
`;

const TextCenter = styled.div`

`;

const ServicesLabel = styled.span`
    height:13px;
    font-family:Roboto;
    font-size:10px;
    font-weight:400;
    font-style:normal;
    font-stretch:normal;
    line-height:2.7;
    letter-spacing:normal;
    text-align:left;
    color:#b4b4b4;
    margin-left:175px;
    font-size:10px;
    color:rgba(0,0,0,.50);
`;

const FormGroup = styled.div`
    margin-bottom: 15px;
    position: relative;
`;

const Icon = styled.i`
    position: absolute;
    top: 0;
    display: block;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    pointer-events: none;
    padding-top: 8px;
    padding-left: 5px;
    ${({ open }) => !open && `
    display: none;
  `}
`;

const SidePanel = (props) => {
    const [ addressExpanded, setAddressExpanded ] = useState(false);
    return (
        <Panel open={props.open}>
            <StaticPanel>
                <SearchPanel>
                    <form onSubmit={props.getZippr}>
                        <FormGroup>
                            <Icon open={props.open}><SearchIcon style={{fontSize: '24px', color:'rgba(0,0,0,.50)'}}/></Icon>
                            <SearchBox open={props.open} type="text" name="zippr" placeholder="Search DDNs and Zipprs" title="Search DDNs and Zipprs" onChange={(e) => props.onZipprChange(e.target.value)} value={props.searchBoxValue}/>
                            <input type="submit" name="Submit" value="Submit" hidden={true} />
                        </FormGroup>
                    </form>
                </SearchPanel>
                <ImagePanel>
                    <ImgPanel>
                        <ZipprImage src={props.image} />
                    </ImgPanel>
                    <DirectionPanel latitude={props.latitude} longitude={props.longitude}/>
                </ImagePanel>
            </StaticPanel>
            <ScrollPanel>
                <InfoPanel>
                    <LogoPanel>
                        {!props.isDdn ?
                            <Logo src="/media/images/zippr_logo.png"/> :
                            <Logo src="/media/images/ddn_logo.png"/>
                        }
                    </LogoPanel>
                    <AddressPanel>
                        <TitleBar>
                            <AddressTitle>{props.zipprCode}</AddressTitle>
                            <a style={{color: 'rgba(0,0,0,.50)', float: 'right', paddingRight: '20px'}}>
                                <ShareIcon onClick={() => props.showModal('share')} style={{fontSize: '20px', cursor: 'pointer'}}/>
                                {props.isDdn && <CommentIcon onClick={() => props.showModal('comment')} style={{fontSize: '20px', marginLeft: '20px', cursor: 'pointer'}}/>}
                            </a>
                        </TitleBar>
                        <AddressTextBar expanded={addressExpanded}>
                            <span>{props.address}</span>
                        </AddressTextBar>
                        <TextCenter>
                            {!addressExpanded ?
                            <ExpandMoreIcon onClick={() => setAddressExpanded(true)} style={{marginLeft: '150px', fontSize: '25px', color: 'rgba(0,0,0,.50)'}}/> :
                            <ExpandLessIcon onClick={() => setAddressExpanded(false)} style={{marginLeft: '150px', fontSize: '25px', color: 'rgba(0,0,0,.50)'}}/>}
                        </TextCenter>
                    </AddressPanel>
                </InfoPanel>
                <ServiceHeader>
                    <ServicesLabel>
                        Services
                    </ServicesLabel>
                </ServiceHeader>
                {(props.zipprCode && <Services zippr={props.zipprCode} latitude={props.latitude} longitude={props.longitude} add_str={props.address} city={props.city} country={props.country} locality={props.locality}/>)}
            </ScrollPanel>
        </Panel>
    )
}

export default SidePanel;