/* eslint-disable */
export const countHyphenOccurances = (zipprCode) => {
    return (zipprCode.match(/-/g) || []).length;
}

var DIVIDER = '-';

export const prepareNewZipprCode = (zipprCode, zipprLength) => {
	var projectCode = zipprCode.indexOf(DIVIDER);
	var newZipprCode = zipprCode.substring(0, projectCode+1);

	var lastCode = zipprCode.substring(projectCode+1, zipprLength);
	var dwellingCode = '';
	if(lastCode.indexOf(DIVIDER) !== -1) {
		dwellingCode = lastCode.substring(lastCode.indexOf(DIVIDER), zipprLength);
		lastCode = lastCode.substring(0, lastCode.indexOf(DIVIDER));
	}

	var count = lastCode.length/3;
	var position = 0;
	for (var i = 0; i < count; i++) {
		newZipprCode = newZipprCode + lastCode.substring(position, position+3) + DIVIDER;
		position = position+3;
	}
	newZipprCode = newZipprCode.slice(0, newZipprCode.length-1);
	if(dwellingCode) {
		newZipprCode += dwellingCode;
	}

    return newZipprCode;
}

export const removeHyphens = (zipprCode) => {
	var arr = zipprCode.split(DIVIDER);

	// if(arr.length < 2) {
	// 	console.log(zipprCode,'inside');
	// }

	var length = arr.length - 1;
	var newZipprCode = '';
	for (var i = 0; i < length; i++) {
		if(arr[i] === ""){
			throw new Error('Zippr Not Found.');
		}
		if(i === 0) {
			newZipprCode = arr[i] + DIVIDER;
			continue;
		}
		newZipprCode += arr[i];
	}

	if(zipprCode.length === 20) {
		newZipprCode += DIVIDER + arr[length];
	} else {
		newZipprCode += arr[length];
	}

	if(zipprCode.charAt(zipprCode.length - 1) === '-'){
		newZipprCode += DIVIDER;
	}
	return newZipprCode;
}