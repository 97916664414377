/* eslint-disable */
import { ActionTypes } from '../constants/index';

const initialState = {
    zippr: null
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case ActionTypes.GET_ZIPPR_SUCCESS:
            return {
                ...state,
                zippr: action.payload.zippr
            }
        default:
            return state;
    }
};

export default reducer;
