/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import Share from './Share';
import Comment from './Comment';

const ModalContent = styled.div`
    position: absolute;
    z-index: 8;
    background-color: #ffffff;
    border: 1px solid #999999;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    outline: 0;
    width: 300px;
    margin-left:50%;
    margin-top:2%;
    transform: translateX(-150px);
    ${({ show }) => !show && `
    display: none;
  `}
`;

const Modal = (props) => {
    return (
        <ModalContent show={props.show}>
            {(props.type === 'share') ?
            <Share 
                show={props.show}
                hide={props.hide}
                phonenumber={props.phonenumber}
                charCount={props.charCount}
                changeHandler={props.changeHandler}
                onSubmit={props.onSubmit}
                messageSent={props.messageSent}
                error={props.error}
            /> :
            <Comment 
                show={props.show}
                hide={props.hide}
                zippr={props.zippr}
            /> }
        </ModalContent>
    )
}

export default Modal;