/**
 * @module Sagas/GitHub
 * @desc GitHub
 */

 /* eslint-disable */
import { all, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

import { ActionTypes } from '../constants/index';

/**
 * Get Repos
 *
 * @param {Object} action
 *
 */

export function* getZippr({ payload }) {
    try {
      var req = {
        'method': 'POST',
        'url': '/tc/searchZippr',
        'data': {'zippr': payload.zippr}
      };
      let response = yield axios(req);
      yield put({
        type: ActionTypes.GET_ZIPPR_SUCCESS,
        payload: { zippr: response.data },
      });
    } catch (err) {
      /* istanbul ignore next */
      yield put({
        type: ActionTypes.GET_ZIPPR_FAILURE,
        payload: err,
      });
    }
  }

/**
 * GitHub Sagas
 */
export default function* root() {
  yield all([takeLatest(ActionTypes.GET_ZIPPR, getZippr)])
};