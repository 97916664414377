/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const WrongContent = styled.div`
  padding: 0;
  text-align: center;
  line-height: 25px;
  padding-top: 20px;
  padding-bottom: 60px;
`;

const FirstDiv = styled.div`
  margin-left: -15px;
  margin-right: -15px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const Container = styled.div`
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
`;

const LogoRow = styled.div`
  padding: 0px 0;
`;

const LogoImage = styled.img`
  max-height: 50px;
  display: inline-block;
`;

const LookText = styled.div`
  color: #58595b;
  margin-top: 48px;
`;

const LookHeading = styled.h2`
  font-size: 48px;
`;

const LookSubHeading = styled.h4`
  font-size: 20.5px;
  margin-top: -8px;
`;

const RedText = styled.span`
  color: #ff6d3f;
`;

const PicBlock = styled.div`
  padding-top: 42px;
  padding-bottom: 40px;
`;

const PicBlockImage = styled.img`
  max-height: 160px;
  display: inline-block;
`;

const SearchRow = styled.div`
  width: 65%;
  font-size: 19px;
  margin: 0 auto;
  color: #6d6e71;
  overflow: hidden;
`;

const ColMd6 = styled.div`
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  float: left;
  width: 50%;
`;

const FormGroupLg = styled.div`
  position: relative;
`;

const FormInput = styled.input`
  width: 100%;
  font-size: 26px;
  color: #ff6d3f;
  text-transform: uppercase;
  line-height: 40px;
  border: 0;
  border-bottom: 3px #ff6d3f solid;
  border-radius: 0;
  padding-left: 0;
  padding-right: 40px;
  ::-webkit-input-placeholder {
    font-size: 25.5px;
    color: rgba(0, 0, 0, 0.4);
    font-weight: 500;
  }
  :-moz-placeholder {
    font-size: 24px;
    font-weight: 500;
  }
  ::-moz-placeholder {
    font-size: 24px;
    font-weight: 500;
  }
  :-ms-input-placeholder {
    font-size: 24px;
    font-weight: 500;
  }
  :focus {
    outline: none;
  }
`;

const SearchButton = styled.button`
  position: absolute;
  right: 0px;
  bottom: 6px;
  background-color: transparent;
  padding: 0 4px;
`;

const LastRow = styled.div`
  font-size: 15px;
  color: #6d6e71;
  padding-top: 50px;
  overflow: hidden;
`;

const LastRowImage = styled.img`
  max-height: 35px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
`;

const NotFound = props => {
  return (
    <WrongContent>
      <FirstDiv>
        <Container>
          <LogoRow>
            <a href="https://zippr.co/">
              <LogoImage src="/media/images/logo.png" />
            </a>
          </LogoRow>
          <LookText>
            <LookHeading>We looked everywhere.</LookHeading>
            <LookSubHeading>
              <RedText>And couldn't find that page</RedText>. However, we do have some suggestions
              below:
            </LookSubHeading>
            <PicBlock>
              <PicBlockImage src="/media/images/ic-search-look.png" />
            </PicBlock>
          </LookText>
          <SearchRow>
            <ColMd6>
              DDN / Zippr code entered is either incorrect or invalid. Check the code and try again.
            </ColMd6>
            <ColMd6>
              <form onSubmit={props.getZippr}>
                <FormGroupLg>
                  <FormInput
                    type="text"
                    name="zippr"
                    onChange={e => props.onZipprChange(e.target.value)}
                    placeholder="NDMC-MAH909090 / ZPPR9090"
                    autoFocus=""
                    value={props.searchBoxValue}
                  />
                  <SearchButton>
                    <FontAwesomeIcon
                      icon={faSearch}
                      style={{ color: '#ff6d3f', fontSize: '25px' }}
                    />
                  </SearchButton>
                </FormGroupLg>
              </form>
            </ColMd6>
          </SearchRow>
          <LastRow>
            <p>
              <LastRowImage src="/media/images/ic-target-red.png" alt="" /> If you are
              looking for other pages then{' '}
              <a href="http://zip.pr/faq.php">
                <RedText>Try our FAQs</RedText>
              </a>
            </p>
          </LastRow>
        </Container>
      </FirstDiv>
    </WrongContent>
  );
};

// const mapDispatchToProps = dispatch => {
//   return {
//     getZippr: ( zipprCode ) => dispatch( actions.getZippr( zipprCode ) ),
//   };
// };

// export default connect( null, mapDispatchToProps )(NotFound);
export default NotFound;
