/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';

import * as actions from '../actions/index';
import { prepareNewZipprCode, removeHyphens } from '../utils';

import BackgroundMap from '../components/BackgroundMap';
import NDMCBackgroundMap from '../components/NDMCBackgroundMap';
import SidePanel from '../components/SidePanel';
import Modal from '../components/Modal';
import Backdrop from '../components/Backdrop';
import NotFound from '../components/NotFound';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

const SideActionPanel = styled.div`
  position: absolute;
  top: 10px;
  left: 409px;
  height: 40px;
  padding: 9px 12px 6px 12px;
  background-color: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  z-index: 5;
  ${({ open }) =>
    !open &&
    `
    left: 0;
  `}
`;

const Home = props => {
  const [sidePanelOpen, setSidePanelOpen] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [phonenumber, setPhonenumber] = useState('');
  const [charCount, setCharCount] = useState(0);
  const [zipprText, setZipprText] = useState('');
  const [searching, setSearching] = useState(true);
  const [isDdn, setIsDdn] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [modalType, setModalType] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    setSearching(true);
    if (
      props.match.params.zippr.length !== 8 &&
      props.match.params.zippr.length !== 14 &&
      props.match.params.zippr.length !== 18 &&
      props.match.params.zippr.length !== 19
    ) {
      let newZipprCode = removeHyphens(props.match.params.zippr);
      if(newZipprCode.length === 14 || newZipprCode.length === 18 || newZipprCode.length === 19){
        setZipprText(newZipprCode.toUpperCase());
      } else {
        setNotFound(true);
        setSearching(false);
      }
    } else {
      setZipprText(props.match.params.zippr.toUpperCase());
    }
  }, [props.match.params.zippr]);

  useEffect(() => {
    if (
      (zipprText.length === 8 || zipprText.length === 14 || zipprText.length === 18 || zipprText.length === 19) &&
      searching
    ) {
      if (zipprText.length === 14 || zipprText.length === 18 || zipprText.length === 19) {
        setIsDdn(true);
      } else {
        setIsDdn(false);
      }
      props.getZippr(zipprText);
      if (!notFound) {
        setZipprText('');
      }
      setSearching(false);
    }
  }, [zipprText]);

  useEffect(() => {
    if (props.zippr.zippr) {
      setNotFound(false);
    }
  }, [props.zippr.zippr]);

  const onChangeHandler = value => {
    if(value.length <= 10){
      setPhonenumber(value);
      setCharCount(value.length);
      setError('');
    }
  };

  const onZipprChange = value => {
    let newZipprCode = removeHyphens(value);
    if(newZipprCode.length === 14){
      setZipprText(newZipprCode);
    } else {
      setZipprText(value);
    }
  };

  const showModalhandler = value => {
    setShowModal(true);
    setModalType(value);
  };

  const hideModalhandler = () => {
    setShowModal(false);
    setPhonenumber('');
    setCharCount(0);
    setMessageSent(false);
    setError('');
  };

  const onSubmitHandler = async e => {
    e.preventDefault();
    if(phonenumber.length !== 10){
      setError('Phone Number Invalid');
    } else {
      var req = {
        method: 'POST',
        url: 'https://zip.pr/claim/sendzipprsms',
        headers: {
          'Content-Type': 'application/json',
        },
        data: { zippr: props.zippr.zippr.zippr, mobile: phonenumber, prefix: 'IN' },
      };
      let response = await axios(req);
      if (response.data.status === 'success') {
        setMessageSent(true);
      } else if (response.data.message === 'Zippr does not exists.'){
        setError(response.data.message);
      } else {
        setError("Phone Number Not Valid")
      }
    }
  };

  const onGetZippr = e => {
    e.preventDefault();
    if(zipprText.length){
      setNotFound(false);
      if (zipprText.length === 14 || zipprText.length === 18 || zipprText.length === 19) {
        setIsDdn(true);
      } else {
        setIsDdn(false);
      }
      props.getZippr(zipprText.toUpperCase());
      props.history.push(`/IN/${zipprText.toUpperCase()}`);
      setZipprText('');
    }
  };

  if (props.zippr.zippr === 'NOT_FOUND') {
    if (!notFound) {
      setNotFound(true);
    }
  }

  let latitude;
  let longitude;
  let zipprImage = '/media/images/noimg.png';
  let zipprCode;
  let address;
  let city;
  let country;
  let locality;

  if (props.zippr.zippr) {
    if (props.zippr.zippr.location) {
      latitude = props.zippr.zippr.location.latitude;
      longitude = props.zippr.zippr.location.longitude;
      if(isDdn){
        zipprCode = prepareNewZipprCode(props.zippr.zippr.zippr);
      } else {
        zipprCode = props.zippr.zippr.zippr;
      }
      address = props.zippr.zippr.complete_address;
      city = props.zippr.zippr.address_v3.city;
      country = props.zippr.zippr.address_v3.country;
      locality = props.zippr.zippr.address_v3.locality;
    }
    if (props.zippr.zippr.image) {
      zipprImage = props.zippr.zippr.image;
    }
  }

  console.log("Logs from frontend");

  if (!notFound) {
    return (
      <div>
        <Helmet title={zipprCode} />
        <Modal
          show={showModal}
          hide={hideModalhandler}
          phonenumber={phonenumber}
          charCount={charCount}
          changeHandler={onChangeHandler}
          onSubmit={onSubmitHandler}
          messageSent={messageSent}
          type={modalType}
          zippr={zipprCode}
          error={error}
        />
        <Backdrop show={showModal} hide={hideModalhandler} />
        {zipprCode && zipprCode.substring(0,4) === 'NDMC' ?
        <NDMCBackgroundMap latitude={latitude} longitude={longitude} /> :
        <BackgroundMap latitude={latitude} longitude={longitude} /> }
        <SidePanel
          open={sidePanelOpen}
          showModal={showModalhandler}
          getZippr={onGetZippr}
          onZipprChange={onZipprChange}
          zipprCode={zipprCode}
          address={address}
          city={city}
          country={country}
          locality={locality}
          latitude={latitude}
          longitude={longitude}
          searchBoxValue={zipprText}
          isDdn={isDdn}
          image={zipprImage}
        />
        <SideActionPanel open={sidePanelOpen}>
          {sidePanelOpen ? (
            <a onClick={() => setSidePanelOpen(false)}>
              <CloseIcon style={{ fontSize: '20px', color: 'rgba(0,0,0,.55)' }} />
            </a>
          ) : (
            <a onClick={() => setSidePanelOpen(true)}>
              <SearchIcon style={{ fontSize: '20px', color: 'rgba(0,0,0,.55)' }} />
            </a>
          )}
        </SideActionPanel>
      </div>
    );
  } else {
    return (
      <NotFound
        history={props.history}
        getZippr={onGetZippr}
        onZipprChange={onZipprChange}
        searchBoxValue={zipprText}
      />
    );
  }
};

const mapStateToProps = state => {
  return {
    zippr: state.zippr,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getZippr: zipprCode => dispatch(actions.getZippr(zipprCode)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Home);
