/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';

const ModalBody = styled.div`
    position: relative;
    padding: 15px;
`;

const ClosePanel = styled.a`
    position:absolute;
    right:10px;
    font-size:18px;
    color:#ed5d2f;
`;

const ShareTPanel = styled.div`
    color:#ed5d2f;
`;

const ShareTPanelTitle = styled.div`
    height:26px;
    font-family:Roboto;
    font-size:20px;
    font-weight:500;
    font-style:normal;
    font-stretch:normal;
    line-height:1.2;
    letter-spacing:normal;
`;

const ShareFPanel = styled.div`
    clear:both;
    padding-top:20px;
    margin-bottom:20px;
`;

const MBS = styled.div`
    margin-bottom:5px;
`;

const TLabel = styled.span`
    height:16px;
    font-size:12px;
    font-weight:400;
    font-style:normal;
    font-stretch:normal;
    line-height:1.33;
    letter-spacing:normal;
    text-align:left;
    color:rgba(0,0,0,.54)
`;

const HasFeedback = styled.div`
    position: relative;
`;

const CountryCode = styled.span`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 8;
    display: block;
    width: 34px;
    height: 34px;
    line-height: 50px;
    text-align: center;
    pointer-events: none;
    width:30px;
    height:40px;
    left:0;
    top:-9px;
    font-size:16px;
    color:#747474;
    font-weight:800;
`;

const NumberInput = styled.input`
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555555;
    background-color: #ffffff;
    background-image: none;
    border: 0px solid #cccccc;
    border-radius: 0px;
    /*  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);*/
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    border-bottom:solid 2px #ed5d2f;
    padding-left:30px;
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    :focus {
        outline: none;
    }
`;

const CharCount = styled.span`
    float: right !important;
    height:13px;
    font-family:Roboto;
    font-size:10px;
    font-weight:400;
    font-style:normal;
    font-stretch:normal;
    line-height:1.3;
    letter-spacing:normal;
    color:#ed5d2f
`;

const SubmitButtonDiv = styled.div`
    padding-top:20px;
    clear:both
`;

const SubmitButton = styled.button`
    float: right !important;
    height:19px;
    padding:0;
    font-size:14px;
    font-weight:500;
    font-style:normal;
    font-stretch:normal;
    line-height:1.43;
    letter-spacing:normal;
    text-align:right;
    border:none;
    color:#2d8300
    :focus {
        outline: none
    }
`;

const ErrorDiv = styled.div`
    color: #a94442;
    text-align: center;
`;

const SuccessMessage = styled.div`
    text-align: center;
    margin-top:10px;
`;

const SuccessMessageSpan = styled.span`
    height:21px;
    -webkit-text-stroke:1px transparent;
    font-size:16px;
    font-weight:400;
    font-style:normal;
    font-stretch:normal;
    line-height:1.25;
    letter-spacing:normal;
    color:#6ac259
`;

const Share = (props) => {
    return (
        <ModalBody>
            <ClosePanel onClick={props.hide}>
                <CloseIcon style={{fontSize: '24px'}}/>
            </ClosePanel>
            <ShareTPanel>
                <ShareTPanelTitle>Share via SMS</ShareTPanelTitle>
            </ShareTPanel>
            {props.error && <ErrorDiv>{props.error}</ErrorDiv>}
            {(!props.messageSent) ? 
                <div>		
                    <ShareFPanel>
                        <form name="share_zd" noValidate autoComplete="off">
                            <input type="hidden" name="zippr" ng-model="obj.zippr" />
                            <MBS>
                                <TLabel>Enter Phone Number</TLabel>
                            </MBS>
                            <HasFeedback>
                                <CountryCode>+91</CountryCode>
                                <NumberInput onChange={(e) => props.changeHandler(e.target.value)} id="share-phone-no" type="text" name="phone_number" value={props.phonenumber} minlength="10" maxlength="10" placeholder="Phone Number" invalid-message="\'Phone Number is invalid.\'" required-message="\'Phone Number is required.\'" validate-on="dirty" required={true}/>
                                <CharCount>{props.charCount}/10</CharCount>
                            </HasFeedback>
                            <SubmitButtonDiv>
                                {/* <img class="pull-right plm" src="/static/images/loader.gif" ng-show="isReqSending" /> */}
                                <SubmitButton onClick={props.onSubmit} type="submit" title="Submit">Submit</SubmitButton>
                            </SubmitButtonDiv>
                        </form>
                    </ShareFPanel>
                </div> :
                <SuccessMessage>
                    <img src="/media/images/checked.png" alt="check" width="120px" height="120px" />
                    <div style={{marginTop:'10px'}}>
                        <SuccessMessageSpan>SMS sent successfully!</SuccessMessageSpan>
                    </div>
                </SuccessMessage>       
            }
            
        </ModalBody>
    )
}

export default Share;