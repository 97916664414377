import { keyMirror } from '../modules/helpers';

/**
 * @namespace Constants
 * @desc App constants
 */

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
/* eslint-disable */
export const ActionTypes = keyMirror({
  SHOW_ALERT: undefined,
  HIDE_ALERT: undefined,
  GET_ZIPPR: undefined,
  GET_ZIPPR_SUCCESS: undefined,
  GET_ZIPPR_FAILURE: undefined
});

/**
 * @constant {Object} STATUS
 * @memberof Constants
 */
export const STATUS = {
  IDLE: 'idle',
  RUNNING: 'running',
  READY: 'ready',
  SUCCESS: 'success',
  ERROR: 'error',
};
