/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import DirectionsIcon from '@material-ui/icons/Directions';

const Direction = styled.div`
    position:absolute;
    right:10px;
    bottom:10px;
    height:48px;
    width:48px;
    border-radius:50%;
    background-color:#0232ff;
    cursor:pointer;
    text-align:center;
`;

const BlockSpan = styled.span`
    display:block;
    margin-bottom:-5px;
`;

const Go = styled.span`
    font-size:14px;
    font-weight:100;
    font-style:normal;
    font-stretch:normal;
    line-height:1.08;
    letter-spacing:normal;
    color:#fff;
`;

const DirectionPanel = (props) => {

    const getLocationSuccess = (position) => {
        window.open('//maps.google.com/maps?saddr=' + position.coords.latitude + ',' + position.coords.longitude + '&daddr=' + props.latitude + ',' + props.longitude)
    }
    
    const getDirections = () => {
        navigator.geolocation.getCurrentPosition(getLocationSuccess);
    }

    return (
        <Direction onClick={getDirections}>
            <a>
                <BlockSpan>
                    <DirectionsIcon style={{fontSize: '22px', marginTop: '3px', color: 'white'}}/>
                </BlockSpan>
                <Go>GO</Go>
            </a>
        </Direction>
    )
}

export default DirectionPanel;