/* eslint-disable */
import React, { useState } from 'react';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';

const ModalBody = styled.div`
    position: relative;
    padding: 15px;
`;

const ClosePanel = styled.a`
    position:absolute;
    right:10px;
    font-size:18px;
    color:#ed5d2f;
`;

const FeedbackTPanel = styled.div`
    color:#ed5d2f;
`;

const FeedbackTitle = styled.div`
    font-family: Roboto,sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
`;

const ErrorDiv = styled.div`
    color: #a94442;
    text-align: center;
`;

const FeedbackFPanel = styled.div`
    clear:both;
    padding-top:20px;
    margin-bottom:20px;
`;

const FormItems = styled.div`
    margin-bottom:15px
`;

const Items = styled.div`
    margin-bottom:5px
`;

const FormLabels = styled.span`
    height:16px;
    font-size:12px;
    font-weight:400;
    font-style:normal;
    font-stretch:normal;
    line-height:1.33;
    letter-spacing:normal;
    text-align:left;
    color:rgba(0,0,0,.54);
`;

const NameInput = styled.input`
    display: block;
    width: 100%;
    color: #555555;
    background-color: #ffffff;
    background-image: none;
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    height:21px;
    padding:0;
    font-family:Roboto;
    font-size:16px;
    font-weight:400;
    font-style:normal;
    font-stretch:normal;
    line-height:1.5;
    letter-spacing:normal;
    text-align:left;
    border-radius:0;
    -webkit-box-shadow:inset 0 0 0 rgba(0,0,0,.075);
    box-shadow:inset 0 0 0 rgba(0,0,0,.075);
    -o-transition:border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition:border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    border:none;
    border-bottom:solid 2px #ed5d2f;
    :focus {
        outline: none;
        border-color:#66afe9;
    };
`;

const CharCount = styled.span`
    float: right !important;
    height:13px;
    font-family:Roboto;
    font-size:10px;
    font-weight:400;
    font-style:normal;
    font-stretch:normal;
    line-height:1.3;
    letter-spacing:normal;
    color:#ed5d2f
`;

const FeedbackDiv = styled.div`
    position: relative;
`;

const CountryCode = styled.span`
    position: absolute;
    width:30px;
    height:40px;
    left:0;
    top:-9px;
    font-size:16px;
    line-height:33px;
    color:#747474
`;

const PhoneNumberInput = styled.input`
    display: block;
    width: 100%;
    color: #555555;
    background-color: #ffffff;
    background-image: none;
    height:21px;
    padding:0;
    font-family:Roboto;
    font-size:16px;
    font-weight:400;
    font-style:normal;
    font-stretch:normal;
    line-height:1.5;
    letter-spacing:normal;
    text-align:left;
    border-radius:0;
    -webkit-box-shadow:inset 0 0 0 rgba(0,0,0,.075);
    box-shadow:inset 0 0 0 rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    border:none;
    border-bottom:solid 2px #ed5d2f;
    padding-left:30px;
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    :focus {
        outline: none;
        border-color:#66afe9;
    };
`;

const FeedbackInput = styled.textarea`
    display: block;
    width: 100%;
    color: #555555;
    background-color: #ffffff;
    background-image: none;
    height:21px;
    padding:0;
    font-family:Roboto;
    font-size:16px;
    font-weight:400;
    font-style:normal;
    font-stretch:normal;
    line-height:1.5;
    letter-spacing:normal;
    text-align:left;
    border-radius:0;
    -webkit-box-shadow:inset 0 0 0 rgba(0,0,0,.075);
    box-shadow:inset 0 0 0 rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    border:none;
    border-bottom:solid 2px #ed5d2f;
    :focus {
        outline: none;
        border-color:#66afe9;
    };
`;

const NextDiv = styled.div`
    padding-top:20px;
    clear:both
`;

const SubmitButton = styled.button`
    float: right !important;
    height:19px;
    padding:0;
    font-size:14px;
    font-weight:500;
    font-style:normal;
    font-stretch:normal;
    line-height:1.43;
    letter-spacing:normal;
    text-align:right;
    border:none;
    color:#2d8300;
`;

const OTPInput = styled.input`
    display: block;
    width: 100%;
    color: #555555;
    background-color: #ffffff;
    background-image: none;
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    height:21px;
    padding:0;
    font-family:Roboto;
    font-size:16px;
    font-weight:400;
    font-style:normal;
    font-stretch:normal;
    line-height:1.5;
    letter-spacing:normal;
    text-align:left;
    border-radius:0;
    -webkit-box-shadow:inset 0 0 0 rgba(0,0,0,.075);
    box-shadow:inset 0 0 0 rgba(0,0,0,.075);
    -o-transition:border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition:border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    border:none;
    border-bottom:solid 2px #ed5d2f;
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    :focus {
        outline: none;
        border-color:#66afe9;
    };
`;

const ResendOTPDiv = styled.div`
    text-align: center;
    margin-top:10px;
`;

const Resend = styled.a`
    height:16px;
    font-size:12px;
    font-weight:400;
    font-style:normal;
    font-stretch:normal;
    line-height:1.33;
    letter-spacing:normal;
    text-align:left;
    color:rgba(0,0,0,.54);
`;

const PrevButton = styled.button`
    float: left !important;
    height:19px;
    padding:0;
    font-size:14px;
    font-weight:500;
    font-style:normal;
    font-stretch:normal;
    line-height:1.43;
    letter-spacing:normal;
    text-align:right;
    border:none;
    color:#2d8300;
`;

const ThankYouDiv = styled.div`
    text-align: center;
`;

const ThankYouMessageDiv = styled.div`
    margin-top:10px;
`;

const ThankYouMessage = styled.span`
    height:21px;
    -webkit-text-stroke:1px transparent;
    font-size:16px;
    font-weight:400;
    font-style:normal;
    font-stretch:normal;
    line-height:1.25;
    letter-spacing:normal;
    color:#6ac259;
`;

const Comment = (props) => {
    const [formBody, setFormBody] = useState(true);
    const [OTPBody, setOTPBody] = useState(false);
    const [ThankYouBody, setThankYouBody] = useState(false);
    const [name, setName] = useState('');
    const [nameCharCount, setNameCharCount] = useState(0);
    const [phonenumber, setPhonenumber] = useState('');
    const [phonenumberCharCount, setPhonenumberCharCount] = useState(0);
    const [feedback, setFeedback] = useState('');
    const [feedbackCharCount, setFeedbackCharCount] = useState(0);
    const [OTP, setOTP] = useState('');
    const [OTPCharCount, setOTPCharCount] = useState(0);
    const [secret, setSecret] = useState('');
    const [error, setError] = useState('');

    const closeModalhandler = () => {
        setThankYouBody(false);
        setOTPBody(false);
        setFormBody(true);
        setName('');
        setNameCharCount(0);
        setPhonenumber('');
        setPhonenumberCharCount(0);
        setFeedback('');
        setFeedbackCharCount(0);
        setOTP('');
        setOTPCharCount(0);
        setSecret('');
        setError('');
        props.hide();
    }

    const submitFormHandler = async (e) => {
        e.preventDefault();
        if(name.length < 1){
            setError('Name Required');
        } else if (phonenumber.length !== 10){
            setError('Phone Number Invalid');
        } else if (feedbackCharCount < 12){
            setError("Feedback should be minimum 12 characters");
        } else {
            var req = {
                method: 'POST',
                url: '/tc/send/otp',
                headers: {
                  'Content-Type': 'application/json'
                },
                data: { zippr: props.zippr, phoneNumber: phonenumber, isDdn: true },
            };
            let response = await axios(req);
            if(response.data.secret){
                setSecret(response.data.secret);
                setFormBody(false);
                setOTPBody(true);
            } else {
                setError("Phone Number Not Valid")
            }
        }
    }

    const prevHandler = () => {
        setOTPBody(false);
        setFormBody(true);
    }

    const submitOTPhandler = async (e) => {
        e.preventDefault();
        if(OTPCharCount !== 6){
            setError('Invalid OTP');
        } else  {
            var req = {
                method: 'POST',
                url: '/tc/verifyotpandactivate',
                headers: {
                  'Content-Type': 'application/json'
                },
                data: { 
                    zippr: props.zippr, 
                    phoneNumber: phonenumber, 
                    isDdn: true,
                    secret,
                    token: OTP,
                    name,
                    feedback_type: "feedback",
                    feedback
                },
            };
            let response = await axios(req);
            if(response.data.ok){
                setOTPBody(false);
                setThankYouBody(true);
            } else {
                setError('Invalid OTP');
            }
        }
    }

    const changeHandler = (value, field) => {
        setError('');
        switch(field) {
            case 'name':
                if(value.length <= 20){
                    setName(value);
                    setNameCharCount(value.length);
                }
                break;
            case 'phonenumber':
                if(value.length <= 10){
                    setPhonenumber(value);
                    setPhonenumberCharCount(value.length);
                }
                break;
            case 'feedback':
                if(value.length <= 240){
                    setFeedback(value);
                    setFeedbackCharCount(value.length);
                }
                break;
            case 'OTP':
                if(value.length <= 6){
                    setOTP(value);
                    setOTPCharCount(value.length);
                }
                break;
        }
    }

    return (
        <ModalBody>
            <ClosePanel onClick={closeModalhandler}>
                <CloseIcon style={{fontSize: '24px'}}/>
            </ClosePanel>
            <FeedbackTPanel>
                <FeedbackTitle>Feedback</FeedbackTitle>
            </FeedbackTPanel>
		    <ErrorDiv>
                <span>{error}</span>
            </ErrorDiv>
		    <FeedbackFPanel>
		        {formBody && <div>
		            <form onSubmit={submitFormHandler} noValidate autoComplete="off">
                        <FormItems>
		                    <Items>
                                <FormLabels>Enter Name</FormLabels>
                            </Items>
		                    <NameInput onChange={(e) => changeHandler(e.target.value, 'name')} type="text" placeholder="Name" value={name} required={true}/>
		                    <CharCount>{nameCharCount}/20</CharCount>
                        </FormItems>
		                <FormItems>
		                    <Items>
                                <FormLabels>Enter Phone Number</FormLabels>
                            </Items>
		                    <FeedbackDiv>
		                        <CountryCode>+91</CountryCode>
		                        <PhoneNumberInput onChange={(e) => changeHandler(e.target.value, 'phonenumber')} type="text" placeholder="Phone Number" value={phonenumber} required={true}/>
		                        <CharCount>{phonenumberCharCount}/10</CharCount>
                            </FeedbackDiv>
                        </FormItems>
		                <FormItems>
		                    <Items>
                                <FormLabels>Enter Your Feedback</FormLabels>
                            </Items>
		                    <FeedbackInput onChange={(e) => changeHandler(e.target.value, 'feedback')} type="text" placeholder="Feedback" value={feedback} required={true}></FeedbackInput>
		                    <CharCount>{feedbackCharCount}/240</CharCount>
		                </FormItems>
		                <NextDiv>
                            <SubmitButton type="submit" title="Submit">Next</SubmitButton>
                        </NextDiv>
		            </form>
		        </div>}
                {OTPBody && <div>
                    <form onSubmit={submitOTPhandler} noValidate autoComplete="off">
                        <Items>
                            <FormLabels>Enter OTP</FormLabels>
                        </Items>
                        <OTPInput onChange={(e) => changeHandler(e.target.value, 'OTP')} type="text" placeholder="OTP" value={OTP} required={true}/>
                        <CharCount>{OTPCharCount}/6</CharCount>
                        <ResendOTPDiv>
                            <Resend>
                                Resend OTP
                            </Resend>
                        </ResendOTPDiv>
                        <NextDiv>
                            <PrevButton onClick={prevHandler} type="button" title="Prev">Prev</PrevButton>
                            <SubmitButton type="submit" title="Submit">Submit</SubmitButton>
                        </NextDiv>
                    </form>
                </div>}
                {ThankYouBody && <ThankYouDiv>
                    <img src="/media/images/checked.png" width="120px" height="120px" />
                    <ThankYouMessageDiv>
                        <ThankYouMessage>Thank You</ThankYouMessage>
                    </ThankYouMessageDiv>
                </ThankYouDiv>}
            </FeedbackFPanel>
        </ModalBody>
    )
}

export default Comment;