/* eslint-disable */
import React, { useState } from 'react';
import styled from 'styled-components';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import axios from 'axios';

const ServicesPanel = styled.div`
    clear: both;
    margin-bottom:5px;
    position:relative;
`;

const ServiceElement = styled.div`
    width:100%;
    position:relative;
    background-color:#fff;
    padding-left:15px;
    padding-top:14px;
    padding-bottom:14px;
    box-shadow:0 2px 3px 0 rgba(0,0,0,.16);
`;

const ServiceGrid = styled.div`
    height:40px;
    display:flex
`;

const ServiceLogo = styled.img`
    height:40px;
    width:40px;
    border-radius:50%;
`;

const MLL = styled.div`
    margin-left:15px
`;

const ServiceTitle = styled.div`
    height:21px;
    font-family:Roboto;
    font-size:18px;
    font-weight:400;
    font-style:normal;
    font-stretch:normal;
    line-height:1.4;
    letter-spacing:normal;
    text-align:left;
    color:#000343;
`;

const ServiceDescription = styled.div`
    height:16px;
    -webkit-text-stroke:.2px transparent;
    font-family:Roboto;
    font-size:13px;
    font-weight:400;
    font-style:normal;
    font-stretch:normal;
    line-height:1.67;
    letter-spacing:normal;
    text-align:left;
    color:rgba(0,0,0,.51);
`;

const ServiceButton = styled.a`
    display:flex;
    position:absolute;
    right:10px;
    top:25px;
    width:28px;
    height:16px;
    -webkit-text-stroke:1px transparent;
    font-family:Roboto;
    font-size:13px;
    font-weight:400;
    font-style:normal;
    font-stretch:normal;
    line-height:1.67;
    letter-spacing:normal;
    text-align:left;
    color:#2d8300;
    :hover {
        cursor:pointer;
    }
`;
const StanForm = styled.form`
    width:83%;
    margin-left:55px;
    margin-top:18px;
`;
const NameInput = styled.input`
    display: block;
    width: 100%;
    color: #555555;
    background-color: #ffffff;
    background-image: none;
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    height:21px;
    padding:0;
    font-family:Roboto;
    font-size:16px;
    font-weight:400;
    font-style:normal;
    font-stretch:normal;
    line-height:1.5;
    letter-spacing:normal;
    text-align:left;
    border-radius:0;
    -webkit-box-shadow:inset 0 0 0 rgba(0,0,0,.075);
    box-shadow:inset 0 0 0 rgba(0,0,0,.075);
    -o-transition:border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition:border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    border:none;
    border-bottom:solid 1px rgba(132, 138, 138, 0.3);
    margin-bottom:30px;
    :focus {
        outline: none;
        border-color:#66afe9;
    };
`;

const PhoneNumberInput = styled.input`
    display: block;
    width: 100%;
    color: #555555;
    background-color: #ffffff;
    background-image: none;
    height:21px;
    padding:0;
    font-family:Roboto;
    font-size:16px;
    font-weight:400;
    font-style:normal;
    font-stretch:normal;
    line-height:1.5;
    letter-spacing:normal;
    text-align:left;
    border-radius:0;
    -webkit-box-shadow:inset 0 0 0 rgba(0,0,0,.075);
    box-shadow:inset 0 0 0 rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    border:none;
    border-bottom:solid 1px rgba(132, 138, 138, 0.3);
    margin-bottom:30px;
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    :focus {
        outline: none;
        border-color:#66afe9;
    };
`;
const CharCount = styled.span`
    float: right !important;
    height:13px;
    font-family:Roboto;
    font-size:10px;
    font-weight:400;
    font-style:normal;
    font-stretch:normal;
    line-height:1.3;
    letter-spacing:normal;
    color:#ed5d2f;
    margin-top:-20px;
`;
const ErrorDiv = styled.div`
    color: #a94442;
    text-align: center;
`;

const BookButton = styled.button`
    background-color:#b01717;
    height:32px;
    width: 125px;
    border-radius:5px;
    color:#fff;
    font-size:16px;
    font-family:Roboto;
    font-weight:bold;
    margin-bottom:20px;
    margin-left:30%;
`;

const TapText = styled.div`
    position:absolute;
    right:0;
    bottom:0;
    font-family:Roboto;
    font-size:8px;
    color:rgba(87, 92, 92, 0.5);
    margin-right:5px;
    margin-bottom:5px;
    :hover{
        text-decoration:underline;
        color:#2d5ceb;
        cursor:pointer;
    }
`;

const BackDrop = styled.div`
    z-index: 7;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,.50);
    ${({ show }) => !show && `
    display: none;
  `}
`;

const Modal = styled.div`
    position: absolute;
    top: 10%;
    left: 120%;
    z-index: 8;
    background-color: #ffffff;
    border: 1px solid #999999;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    outline: 0;
    width: 300px;
    margin-left:50%;
    margin-top:2%;
    transform: translateX(-150px);
    ${({ show }) => !show && `
    display: none;
  `}
`;

const ModalMessage = styled.div`
    font-family:Roboto;
    font-size:15px;
    padding-left:10px;
    padding-right:20px;
    padding-top:10px;
    padding-bottom:15px;
    border-bottom:solid 1px rgba(132, 138, 138, 0.3);
`;

const ModalCloseDiv = styled.div`
    float: right;
    padding-right:10px;
    padding-top:10px;
    padding-bottom:10px;
`;

const ModalClose = styled.button`
    height:30px;
    width:60px;
    background-color:#4363c4;
    border-radius:3px;
    color:#fff;
    font-family:Roboto;
    font-size:13px;
    ${({ error }) => error && `
    background-color: #d1323a;
  `}
`;

const Services = ({zippr, latitude, longitude, add_str, city, country, locality}) => {
    const [name, setName] = useState('');
    const [nameCharCount, setNameCharCount] = useState(0);
    const [phonenumber, setPhonenumber] = useState('');
    const [phonenumberCharCount, setPhonenumberCharCount] = useState(0);
    const [stanExpand, setStanExpand] = useState(false);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [errorModal, setErrorModal] = useState(false);

    const serviceList = [
        {
            'type': 'link',
            'title': 'Uber',
            'color': '#000343',
            'description': 'India\'s #1 Cab Service',
            'logo_url': 'https://s3.ap-south-1.amazonaws.com/zippr-campaigns/client-images/uber.png',
            'url': `https://m.uber.com/?action=setPickup&pickup[latitude]=${latitude}&pickup[longitude]=${longitude}&pickup[nickname]=${zippr}, ${add_str}&pickup[formatted_address]=${zippr}, ${add_str}`,
            'keys': ['latitude', 'longitude', 'zippr', 'add_str'],
            'zippr_regexes': ['^'],
            'isDdn': false
        },
        {
            'type': 'form',
            'title': 'StanPlus',
            'color': 'rgba(0, 0, 0, 0.87)',
            'description': 'Book an ambulance in 10 seconds',
            'logo_url': 'https://s3.ap-south-1.amazonaws.com/zippr-campaigns/client-images/stanplus.png',
            'collapseForm': true,
            'form_params': [
                {
                    'sno': 1,
                    'name': 'Name',
                    'minLength': 2,
                    'maxLength': 32,
                    'type': 'text',
                    'validator': new RegExp('^.{2,50}$'),
                    'required': true,
                    'param_name': 'name'
                },
                {
                    'sno': 2,
                    'name': 'Phone Number',
                    'minLength': 10,
                    'maxLength': 10,
                    'type': 'text',
                    'validator': new RegExp('^[0-9]{10,10}$'),
                    'required': true,
                    'param_name': 'mobile_number'
                }
            ],
            'form_button_txt': 'Book',
            'form_button_bg_color': '#2d8300',
            'url': 'https://api.stanplus.co.in/v1/data/booking/createbooking?key=14b8127fa6d0c5ba527e721b6a9bb7f33bb99b6b',
            /*'url': 'https://tapi.stanplus.co.in/v1/data/booking/createbooking?key=046c1a814e2dfc0bb9539a5a42b6a91a2bf04195',*/
            'keys': [{'name': 'latitude', 'param_name': 'pickup_lat'}, {'name': 'longitude', 'param_name': 'pickup_long'}],
            'success_msg': 'Request sent successfully. Please wait while we are connecting you with our service provider.',
            'error_msg': 'Services are presently unavailable, please call 040-33911911 to book an ambulance now.',
            'log_resp': ['bid'],
            'zippr_regexes': ['^HYD-', '^VMC-', '^AP']
        },
        {
            'type': 'link',
            'title': 'Bhopal Plus App',
            'color': '#9a0086',
            'description': 'Download for govt. services and utilities',
            'logo_url': 'https://s3.ap-south-1.amazonaws.com/zippr-campaigns/client-images/bhopal.jpeg',
            'url': 'https://bit.ly/2rRfPhp',
            'keys': [],
            'zippr_regexes': ['^MPBP-']
        }
    ];

    let serviceObj = [];

    for (let i = 0; i < serviceList.length; i++) {
        for (let j = 0; j < serviceList[i].zippr_regexes.length; j++){
            let reg = new RegExp(serviceList[i].zippr_regexes[j]);
            if(zippr.match(reg)){ 
                serviceObj.push(serviceList[i]);
            }
        }
    }

    const changeHandler = (value, field) => {
        setError('');
        switch(field) {
            case 'name':
                if(value.length <= 20){
                    setName(value);
                    setNameCharCount(value.length);
                }
                break;
            case 'phonenumber':
                if(value.length <= 10){
                    setPhonenumber(value);
                    setPhonenumberCharCount(value.length);
                }
                break;
        }
    }

    const toggleStanExpand = item => {
        if(item.title === 'StanPlus'){
            setStanExpand(!stanExpand);
        }
    }

    const handleStanBooking = async (e) => {
        e.preventDefault();
        if(name.length <= 2){
            setError("Name is invalid");
        } else if (phonenumber.length < 10){
            setError("Phone Number is invalid")
        } else {
            var req1 = {
                method: 'POST',
                url: 'https://api.stanplus.co.in/v1/data/booking/createbooking?key=14b8127fa6d0c5ba527e721b6a9bb7f33bb99b6b',
                data: { pickup_lat: latitude, pickup_long: longitude, name, mobile_number: phonenumber }
            }
            try{
                let response1 = await axios(req1);
                if(response1.data.success){
                    setMessage(serviceList[1].success_msg);
                    setShowModal(true);
                }
            } catch(e) {
                setMessage(serviceList[1].error_msg)
                setShowModal(true);
                setErrorModal(true);
            }
        }
    }

    const handleCloseModal = () => {
        setShowModal(false);
        setErrorModal(false);
    }
    
    return (
        <div>
            {serviceObj.map(item => (
                <ServicesPanel key={item.title}>    
                    <ServiceElement>
                        <ServiceGrid onClick={()=>toggleStanExpand(item)}>
                            <ServiceLogo src={item.logo_url}/>
                            <MLL>
                                <ServiceTitle>{item.title}</ServiceTitle>
                                <ServiceDescription>{item.description}</ServiceDescription>
                            </MLL>
                                {(item.title !== 'StanPlus') ?
                                <ServiceButton
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.open(item.url, "_blank")
                                        }}
                                >
                                    GO<ChevronRightIcon style={{marginTop: '1px', fontSize: '20px'}}/>
                                </ServiceButton> : null }
                        </ServiceGrid>
                        {(item.title === 'StanPlus') && stanExpand &&
                        <StanForm onSubmit={handleStanBooking}>
                            {error && <ErrorDiv>{error}</ErrorDiv>}
                            <NameInput onChange={(e) => changeHandler(e.target.value, 'name')} type="text" placeholder="Name" value={name} required={true}/>
                            <CharCount>{nameCharCount}/20</CharCount>
                            <PhoneNumberInput onChange={(e) => changeHandler(e.target.value, 'phonenumber')} type="text" placeholder="Phone Number" value={phonenumber} required={true}/>
                            <CharCount>{phonenumberCharCount}/10</CharCount>
                            <BookButton type="button" onClick={handleStanBooking}>Book</BookButton>
                        </StanForm>}
                    </ServiceElement>
                    {(item.title === 'StanPlus') && <TapText onClick={() => toggleStanExpand(item)}>Tap to expand</TapText>}
                </ServicesPanel>
            ))}
            <BackDrop show={showModal} onClick={handleCloseModal}/>
            <Modal show={showModal}>
                <ModalMessage>
                    {message}
                </ModalMessage>
                <ModalCloseDiv>
                    <ModalClose onClick={handleCloseModal} error={errorModal}>Close</ModalClose>
                </ModalCloseDiv>                     
            </Modal>
        </div>
    )
}

export default Services;