/* eslint-disable */
import React, { useEffect } from 'react';

const Root = () => {

  useEffect(() => {
    window.location.assign('https://zippr.co/');
  },[])

  return (
    <div>
    </div>
  )
};

export default Root;
