/**
 * Configuration
 * @module config
 */

const config = {
  name: 'Zippr',
  description: 'Find your location using the 8 character Zippr',
};

export default config;
