/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
  } from "react-google-maps";

const MapWithAMarker = compose(withScriptjs, withGoogleMap)(props => {

    return (
        <GoogleMap 
            defaultZoom={18}
            center={{ lat: props.lat, lng: props.lng }}
            defaultOptions={{
                mapTypeControl: false,
                mapTypeId: window.google.maps.MapTypeId.SATELLITE,
                zoomControl: true,
				zoomControlOptions: {
					position: window.google.maps.ControlPosition.RIGHT_BOTTOM
                },
                scaleControl: true,
				streetViewControl: true,
				streetViewControlOptions: {
					position: window.google.maps.ControlPosition.RIGHT_BOTTOM
				},
                fullscreenControl: false
            }}
        >
            <Marker
                key={props.zippr}
                position={{ lat: props.lat, lng: props.lng}}
                icon="/media/images/marker_new.png"
            >
            </Marker>
        </GoogleMap>
    )
})

const BackgroundMap = ({ latitude, longitude }) => {
    return (
        <MapWithAMarker
            zippr="AEXC6242"
            lat={latitude}
            lng={longitude}
            googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBJpz3MZZwQpRpJRy5Oz0Fu6VraNfspRSo"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `800px` }} />}
            mapElement={<div style={{ height: `100vh` }} />}
        />
    )
}

export default BackgroundMap;
